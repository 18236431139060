import { Box, Grid, Hidden } from '@material-ui/core'

import { About } from '~/types'
import ColorTypography from '~/components/ColorTypography'
import ExternalLink from '~/components/ExternalLink'
import Head from '~/components/Head'
import LineBreak from '~/components/LineBreak'
import React from 'react'
import RichText from '~/components/RichText'
import Scrollable from '~/components/Scrollable'
import SelectedClients from '~/components/SelectedClients'
import { Unicode } from '~/constants/unicode'
import { contentMargin } from '~/styles/sizes'
import { graphql } from 'gatsby'

export const query = graphql`
    {
        sanityAbout {
            email
            instagram {
                url
                title
            }
            selectedClients {
                url
                title
            }
            designer {
                url
                title
            }
            developer {
                url
                title
            }
            _rawText(resolveReferences: { maxDepth: 5 })
        }
    }
`

type ViewProps = {
    data: {
        sanityAbout: About
    }
}

const IndexPage = ({ data }: ViewProps) => {
    const { email, instagram, selectedClients, designer, developer, _rawText } = (
        data || {}
    ).sanityAbout

    return (
        <>
            <Head title="About" />
            <Box position="absolute" top={0} left={0} width="100%" padding={contentMargin}>
                <ColorTypography variant="h1" align="center">
                    MAXIME LETHELIER
                </ColorTypography>
            </Box>
            <Hidden smDown>
                <Box position="absolute" bottom={0} left={0}>
                    <Box padding={contentMargin}>
                        <ColorTypography variant="h1">MAXIME LETHELIER © 2020</ColorTypography>
                    </Box>
                </Box>
            </Hidden>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Scrollable>
                        <Box paddingLeft={contentMargin} paddingRight={contentMargin}>
                            <Hidden mdUp>
                                <RichText body={_rawText} />
                                <LineBreak variant="h1" lines={1} />
                            </Hidden>
                            <SelectedClients clients={selectedClients} />
                            <ExternalLink
                                link={{
                                    title: `EMAIL`,
                                    url: `mailto:${email}`
                                }}
                                display="block"
                            />
                            <ExternalLink
                                link={{ title: instagram.title.toUpperCase(), url: instagram.url }}
                                display="block"
                            />
                            <LineBreak variant="h1" lines={2} />
                            <Box display="flex">
                                <Box paddingRight={15}>
                                    <ColorTypography variant="h1">Website</ColorTypography>
                                </Box>
                                <Box>
                                    <ExternalLink link={designer} display="block" />
                                    <ExternalLink link={developer} />
                                </Box>
                            </Box>
                            <LineBreak variant="h1" lines={3} />
                        </Box>
                    </Scrollable>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} md={6}>
                        <Scrollable>
                            <Box position="relative" paddingRight={contentMargin}>
                                <RichText body={_rawText} />
                            </Box>
                        </Scrollable>
                    </Grid>
                </Hidden>
            </Grid>
        </>
    )
}

export default IndexPage
