import { Box, Grid } from '@material-ui/core'

import ColorTypography from '~/components/ColorTypography'
import ExternalLink from '~/components/ExternalLink'
import LineBreak from '~/components/LineBreak'
import { Link } from '~/types'
import React from 'react'

type ViewProps = {
    clients: Link[]
}

export default ({ clients }: ViewProps) => (
    <>
        <ColorTypography variant="h1">SELECTED CLIENTS</ColorTypography>
        <LineBreak variant="h1" lines={1} />
        <Box maxWidth={700}>
            <Grid container>
                {clients.map((client, index) => (
                    <Grid item xs={12} md={clients.length < 6 ? 12 : 6} key={index}>
                        <ExternalLink link={client} />
                    </Grid>
                ))}
            </Grid>
        </Box>
        <LineBreak variant="h1" lines={2} />
    </>
)
